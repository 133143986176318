// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
}

.loading {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px; /* Spinner size */
    height: 60px; /* Spinner size */
    animation: spin 2s linear infinite; /* Animation */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-spinner {
  text-align: center;
  margin: 50px 0;
}

.loading-spinner div {
  width: 24px;
  height: 24px;
  background-color: #007bff;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Spinner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,gCAAgC;AACnD;;AAEA;IACI,0BAA0B,EAAE,eAAe;IAC3C,8BAA8B,EAAE,SAAS;IACzC,kBAAkB;IAClB,WAAW,EAAE,iBAAiB;IAC9B,YAAY,EAAE,iBAAiB;IAC/B,kCAAkC,EAAE,cAAc;AACtD;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,qBAAqB;EACrB,gDAAgD;AAClD;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".spinner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* Full height of the viewport */\n}\n\n.loading {\n    border: 16px solid #f3f3f3; /* Light grey */\n    border-top: 16px solid #3498db; /* Blue */\n    border-radius: 50%;\n    width: 60px; /* Spinner size */\n    height: 60px; /* Spinner size */\n    animation: spin 2s linear infinite; /* Animation */\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n\n.loading-spinner {\n  text-align: center;\n  margin: 50px 0;\n}\n\n.loading-spinner div {\n  width: 24px;\n  height: 24px;\n  background-color: #007bff;\n  border-radius: 50%;\n  display: inline-block;\n  animation: bounce 1.4s infinite ease-in-out both;\n}\n\n@keyframes bounce {\n  0%, 100% {\n    transform: scale(0);\n  }\n  50% {\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
