// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Overall section for job cards */
.job-cards {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items in the column */
    width: 100%;
    margin: 20px 0; /* Add margin for spacing */
}

/* Heading styling */
h1 {
    font-size: 2rem; /* Set a larger font size for the heading */
    margin-bottom: 20px; /* Spacing below the heading */
    color: #333; /* Dark color for the heading */
}

/* Container for the cards */
.carts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the job cards */
    gap: 20px; /* Space between cards */
    max-width: 1600px; /* Maximum width of the container */
}




/* Card content area */
.card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-grow: 1; /* Allow it to take available space */
}


/* Responsive Design */
@media (max-width: 768px) {
    .cards {
        justify-content: center; /* Center cards on small screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Homes.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAE,+BAA+B;IACpD,WAAW;IACX,cAAc,EAAE,2BAA2B;AAC/C;;AAEA,oBAAoB;AACpB;IACI,eAAe,EAAE,2CAA2C;IAC5D,mBAAmB,EAAE,8BAA8B;IACnD,WAAW,EAAE,+BAA+B;AAChD;;AAEA,4BAA4B;AAC5B;IACI,aAAa;IACb,eAAe;IACf,uBAAuB,EAAE,yBAAyB;IAClD,SAAS,EAAE,wBAAwB;IACnC,iBAAiB,EAAE,mCAAmC;AAC1D;;;;;AAKA,sBAAsB;AACtB;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY,EAAE,qCAAqC;AACvD;;;AAGA,sBAAsB;AACtB;IACI;QACI,uBAAuB,EAAE,kCAAkC;IAC/D;AACJ","sourcesContent":["/* Overall section for job cards */\n.job-cards {\n    display: flex;\n    flex-direction: column;\n    align-items: center; /* Center items in the column */\n    width: 100%;\n    margin: 20px 0; /* Add margin for spacing */\n}\n\n/* Heading styling */\nh1 {\n    font-size: 2rem; /* Set a larger font size for the heading */\n    margin-bottom: 20px; /* Spacing below the heading */\n    color: #333; /* Dark color for the heading */\n}\n\n/* Container for the cards */\n.carts {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center; /* Center the job cards */\n    gap: 20px; /* Space between cards */\n    max-width: 1600px; /* Maximum width of the container */\n}\n\n\n\n\n/* Card content area */\n.card-content {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    flex-grow: 1; /* Allow it to take available space */\n}\n\n\n/* Responsive Design */\n@media (max-width: 768px) {\n    .cards {\n        justify-content: center; /* Center cards on small screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
