// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatteli{
  
  margin-top: 0px;
  
 
  padding: 0px;
  align-items: center;
  /* text-align: center; */
  display: flex;
  justify-content: space-between;
  
}
/* .whatteli img {
  width: 24px;
  height:24px;
} */
.what{
  margin-top: 10px;
  margin-left: 50px;
  margin-bottom: 16px;
  width: 70px;
  height: 30px;
 
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

 .teli{
  margin-top: 10px;
  margin-right: 50px;
  width: 55px;
  height: 30px;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  
}
#mainhomeheading{
  background-color: rgb(119, 238, 238);
  text-align: center;
  padding: 10px;
  

}





















/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,eAAe;;;EAGf,YAAY;EACZ,mBAAmB;EACnB,wBAAwB;EACxB,aAAa;EACb,8BAA8B;;AAEhC;AACA;;;GAGG;AACH;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;EACX,YAAY;;EAEZ,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;AACrB;;CAEC;EACC,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;;AAEpB;AACA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,aAAa;;;AAGf;;;;;;;;;;;;;;;;;;;;;;AAsBA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;CAsCC","sourcesContent":[".whatteli{\n  \n  margin-top: 0px;\n  \n \n  padding: 0px;\n  align-items: center;\n  /* text-align: center; */\n  display: flex;\n  justify-content: space-between;\n  \n}\n/* .whatteli img {\n  width: 24px;\n  height:24px;\n} */\n.what{\n  margin-top: 10px;\n  margin-left: 50px;\n  margin-bottom: 16px;\n  width: 70px;\n  height: 30px;\n \n  border-radius: 8px;\n  justify-content: center;\n  text-align: center;\n  align-items: center;\n}\n\n .teli{\n  margin-top: 10px;\n  margin-right: 50px;\n  width: 55px;\n  height: 30px;\n  border-radius: 8px;\n  justify-content: center;\n  text-align: center;\n  \n}\n#mainhomeheading{\n  background-color: rgb(119, 238, 238);\n  text-align: center;\n  padding: 10px;\n  \n\n}\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n/*.App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
